<template>
    <div class="dark:bg-gray-900 bg-white py-24 text-gray-100">
        <div class="lg:container mx-auto">
            <a class="py-3 px-6 bg-blue-400 sm:m-10 lg:m-0 hover:bg-teal-400" @click="$router.back()">Назад
            </a>
            <h1 class="sm:p-10 lg:p-0 dark:text-gray-100 text-gray-700 text-4xl my-10">{{ item.title }}</h1>
            <div class="dark:bg-gray-800 bg-white dark:shadow-none shadow-2xl rounded p-10">
                <span class="block text-right text-md dark:text-gray-300 text-gray-800 pb-10">{{
                        item.created_at
                    }}</span>
                <div class="text-lg flex flex-col dark:text-gray-100 text-gray-700 " v-html="item.description"></div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import {mapGetters} from "vuex";

export default {
    name: "SoftwareUpdateDetail",
    meta: {
        title: 'Программный продукт'
    },
    data: () => ({
        item: {}
    }),
    computed: {
        ...mapGetters(['lang'])
    },
    created() {
        this.fetch()
    },
    watch: {
        lang() {
            fetch()
        }
    },
    methods: {
        async fetch() {
            let code = this.$route.params.code
            axios.get(`https://declarant.by/rest/software/updates/${code}`).then(res => {
                this.item = res.data.data
            })
        }

    }
}
</script>

<style scoped>

p {
    margin: 0 0 20px 0 !important;
    display: flex !important;
}


</style>